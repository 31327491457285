import * as React from "react"
import { Link, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

import Layout from "../components/layout"
import Seo from "../components/seo"

// import ppt from "../images/Egon-Schiele-Illustrations-Routledge.pptx";

const HomeContainer = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`

const IndexPage = ({ data }) => (
  <Layout>
    <Seo title="Home" />
    <HomeContainer>
      <div className="content-container">
        <h1>Claude Cernuschi</h1>
        <p>
          <a
            href={
              "https://cernuschinyc.b-cdn.net/egon-schiele-illustrations-routledge.pptx"
            }
          >
            Download File
          </a>
        </p>
      </div>
    </HomeContainer>
  </Layout>
)

export default IndexPage

// export const query = graphql`
//   query {
//     ppt: allFile(
//       filter: { name: { eq: "Egon-Schiele-Illustrations-Routledge" } }
//     ) {
//       edges {
//         node {
//           id
//           name
//           publicURL
//         }
//       }
//     }
//   }
// `;
